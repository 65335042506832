import React, { useEffect, useRef, useState } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sort from "../../components/elements/Sort"
import ProductGrid from "../../components/elements/ProductGrid"

// export const query = graphql`
//     {
//         allWpProduct {
//             edges {
//                 node {
//                     title
//                     price
//                     uri
//                     databaseId
//                 }
//             }
//         }
//     }
// `
const CategoryPage = props => {

    const [sortedBy, setSortedBy] = useState('new');
    const [showMobileSort, setShowMobileSort] = useState(false);

    let products = [];

    

    // // GET PRODUCTS

    // props.data.allWpProduct.edges.forEach((product, key) => {

    //     products.push({
    //         id: product.node.id,
    //         title: product.node.title,
    //         price: product.node.price,
    //         destination: product.node.uri,
    //         detail: '',
    //     })

    // })

    useEffect(() => {

        const thisProducts = [...products];
        console.log("SORT BY", thisProducts)

        if (thisProducts.length > 0) {

            switch (sortedBy) {
                case 'new':
                    thisProducts.sort((a, b) => {

                        return parseInt(b.id) - parseInt(a.id);

                    });

                    break;

                case 'priceup':
                    thisProducts.sort((a, b) => {

                        let priceA = parseFloat(a.price.replace(',', '.'));
                        let priceB = parseFloat(b.price.replace(',', '.'));

                        return priceA - priceB;

                    });

                    break;

                case 'pricedowm':
                    thisProducts.sort((a, b) => {

                        let priceA = parseFloat(a.price.replace(',', '.'));
                        let priceB = parseFloat(b.price.replace(',', '.'));

                        return priceB - priceA;

                    });

                    break;

            }

            // setProducts(thisProducts);

        }


    }, [sortedBy]);

    return (
        <Layout
        >
            <SEO title={"Alle Prodkte"} />
            <div className={`container`} style={{ minHeight: '90vh', paddingTop: 100 }}>
                <div className={`col12`}>
                    <div>
                        <h1>Alle Prodkte</h1>

                        <div className={`sortWrapper ${showMobileSort ? "active" : null}`}>
                            <Sort
                                title="Sortieren nach"
                                products={products}
                                value={sortedBy}
                                options={[
                                    {
                                        'title': 'Neuste zuerst',
                                        'id': 'new'
                                    },
                                    {
                                        'title': 'Preis aufsteigend',
                                        'id': 'priceup',
                                    },
                                    {
                                        'title': 'Preis Absteigend',
                                        'id': 'pricedowm'
                                    }
                                ]}
                                onChange={(value) => setSortedBy(value)}
                            />

                        </div>
                        <ProductGrid
                            products={products}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CategoryPage